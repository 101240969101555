<template>
  <div class="organizational">
      <Head @closeMenu="closeOpenMenu" />
       <Menu ref="menu" />
       <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
      <div class="banner">
          <!--       <!-- <div class="forward">
          </div> --> 
      </div>
      <div class="contents" id="contents">
          <div class="contentCenter">
              <h3 class="title" id="title">组织架构</h3>
              <p class="lines">
                <span class="lineCenter"></span>
              </p>
              <div class="contextCenter">
                     <div class="center">
                     </div>
                     <div class="EnName"></div>
              </div>
          </div>
      </div>
        <Footer />
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Footer from "@/components/footer.vue";
import Menu from "@/components/menu.vue";
import Topreturn from "@/components/Topreturn.vue";
import setScrollTop from "../../utils/setScrollTop.js";
export default {
 components: {
    Head,
    Menu,
    Footer,
    Topreturn
  },
    methods: {
    closeOpenMenu(t) {
      console.log(t);
      this.$refs.menu.menuShow = t;
    },
  },
  watch: {
    $route(to, from) {
      setScrollTop('title');
    },
  },
  mounted(){
    setScrollTop('title');
  },


}

</script>

<style scoped>
/* banner图开始 */
 .banner{
     width: 100%;
    height: 1080px;
    background-image: url("../../assets/image/aboutGroup/index/banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
 }
 .banner .forward{
    width: 164px;
    height: 44px;
    background-image: url("../../assets/image/aboutGroup/index/BnnerContext.png");
     background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 58px;
    bottom: 26px;
 }
 /* banner图结束 */

 /* 主体内容开始 */
 .contents{
    width: 100%;
    height: 1080px;
    background-image: url("../../assets/image/aboutGroup/index/backgroundImage.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding-bottom: 50px;
 }
 .contents .contentCenter{
    padding-top: 78px;
    color: #fff;
    max-width: 1466px;
    margin: 0 auto;
 }
 .contents .contentCenter .title{
   font-size: 40px;
   font-family: IOS9W4;
   font-weight: bold;
   color: #17171C;
 }
 .contents .contentCenter .lines{
   margin-bottom: 36px;
 }
 .contents .contentCenter .lines .lineCenter{
    width: 70px;
    height: 4px;
    background: #3C4047;
    border-radius: 2px;
    display: inline-block;
 }
 .contents .contentCenter .contextCenter{
    color: #333333;
    /* display: flex; */
    padding-top: 37px;
    /* justify-content: space-between; */
 }
  .contents .contentCenter .contextCenter .center{
      margin: 0 auto;
      width: 1340px;
      height: 734px;
      background-image: url("../../assets/image/aboutGroup/organizational/content.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
  }
  .contents .contentCenter .contextCenter  .EnName{
    width: 739px;
    height: 58px;
    background-image: url("../../assets/image/aboutGroup/organizational/EnName.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 52px;
    
  }

  /* hover 事件 */
 /* .contents .contentCenter .contextCenter .left:hover {
    background-size: 105% 105%;
    transition: 0.5s;
} */
</style>